import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  STORE_TOKEN,
  ADMIN_GET_DETAILS,
  AUTH_FAIL,
} from "./types";
import { setAlert } from "./alertAction";
import setAuthToken from "../Helpers/setAuthToken";
import Auth from "src/Auth";
import { tokenRequest } from "../AuthToken";
const AuthNew = tokenRequest();
// console.log("🚀  AuthNew:", AuthNew);

// import { tokenRequest } from "../AuthToken";
// const Auth = tokenRequest();

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const PROXY = process.env.REACT_APP_URL + "admin/";
const NewPROXY = process.env.REACT_APP_URL;

export const AdminLogin = (userObj) => async (dispatch) => {
  const body = JSON.stringify(userObj);
  try {
    const res = await axios.post(PROXY + "login", body, config);
    setAuthToken(res.data.ResponseBody);

    if (res.data.succeeded === true) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.ResponseBody,
      });
      dispatch(AdminGet(res.data.ResponseBody.token));
    }
    return res.data;
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
    });
    if (error.response) {
      dispatch(setAlert(error.response.data.ResponseMessage, "danger"));
    } else {
      dispatch(setAlert("Error occurred, Please try again later", "danger"));
    }
    return error.response.data;
  }
};

export const AdminGet = (newToken) => async (dispatch) => {
  // if (localStorage.token) {
  //   setAuthToken(localStorage.token);
  // }
  // console.log("🚀  AuthNew:", AuthNew, config);
  const newAuth = {
    headers: {
      authorization: `bearer ${newToken}`,
    },
  };
  try {
    const res = await axios.get(`${PROXY}get_profile`, newAuth);
    dispatch({
      type: ADMIN_GET_DETAILS,
      payload: res.data.ResponseBody,
    });
  } catch (error) {
    dispatch({
      type: AUTH_FAIL,
    });
    return error;
  }
};

export const AdminUpdate = (data) => async (dispatch) => {
  const res = await axios.post(`${PROXY}update_profile`, data, config);
  return res.data;
};

export const store_Token_Data = (data) => async (dispatch) => {
  dispatch({
    type: STORE_TOKEN,
    payload: data,
  });
};

export const forgotPassword = (userObj) => async (dispatch) => {
  try {
    const body = JSON.stringify(userObj);
    const res = await axios.post(PROXY + "forget_password", body, config);
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const OTPverify = (userObj, newToken) => async (dispatch) => {
  const newAuth = {
    headers: {
      authorization: `bearer ${newToken}`,
    },
  };
  const body = JSON.stringify(userObj);
  const res = await axios.post(PROXY + "verify_otp", userObj, newAuth);
  return res.data;
};

export const changePassword = (data, newToken) => async (dispatch) => {
  const newAuth = {
    headers: {
      authorization: `bearer ${newToken}`,
    },
  };
  const res = await axios.post(PROXY + "reset_password ", data, newAuth);
  return res.data;
};

export const Logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const userOTPVerify = (data, newToken) => async (dispatch) => {
  const newAuth = {
    headers: {
      authorization: `bearer ${newToken}`,
    },
  };

  const res = await axios.post(NewPROXY + "user/verify", data, newAuth);
  return res.data;
};

export const userPasswordChange = (formData) => async (dispatch) => {
  const token = {
    headers: {
      authorization: `bearer ${localStorage.getItem("token")}`,
    },
  };
  const res = await axios.post(`${PROXY}update_password`, formData, token);
  return res.data;
};
