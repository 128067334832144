import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./scss/style.scss";
import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { AdminGet } from "./actions/authAction";
import { connect, useSelector } from "react-redux";
import setAuthToken from "./Helpers/setAuthToken";
import { Spinner } from "reactstrap";

const loading = (
<div className="loader-size">
       <Spinner
         color="info"
         style={{
           height: "3rem",
           width: "3rem",
         }}
         type="grow"
       >
         Loading...
       </Spinner>
     </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ForgotPassword = React.lazy(() => import("./views/pages/ForgotPassword"));
const VerifyOtp = React.lazy(() => import("./views/pages/VerifyOtp"));
const UpdatePassword = React.lazy(() => import("./views/pages/UpdatePassword"));
const UserVerify = React.lazy(() => import("./views/UserVerify/UserVerify"));

const App = (props) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const checkAuth = useSelector((state) => state.auth);
  const { isLogged, loading, token } = checkAuth;

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  useEffect(() => {
    const handleOnlineStatus = () => setIsOnline(true);
    const handleOfflineStatus = () => setIsOnline(false);

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOfflineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOfflineStatus);
    };
  }, []);

  

  useEffect(() => {
    props.AdminGet(token);

    if (!isOnline) {
      toast.error("Internet connection is interrupted try again later", {
        position: "bottom-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      toast.dismiss(); // Dismiss any existing toast messages when the internet connection is restored
    }
  }, [isOnline]);

  return (
    <Router basename="/">
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/" name="Login Page" element={<Login />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
          <Route
            path="/forgot-password"
            name="Forgot Password"
            element={<ForgotPassword />}
          />
          <Route
            path="/forgot-password/VerifyOtp"
            name="Verify Otp"
            element={<VerifyOtp />}
          />
          <Route
            path="/reset-password"
            name="Update Password"
            element={<UpdatePassword />}
          />
          <Route
            path="/verify-email/:token"
            name="User Verify"
            element={<UserVerify />}
          />
        </Routes>
      </Suspense>
      <ToastContainer />
    </Router>
  );
};

// export default App;
export default connect(null, { AdminGet })(App);
