export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_ALERT = "SET_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const DELETE_ALL_ALERT = "DELETE_ALL_ALERT";
export const LOGOUT = "LOGOUT";
export const ADMIN_GET_DETAILS = "ADMIN_GET_DETAILS";
export const AUTH_FAIL = "AUTH_FAIL";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_DETAILS = "GET_MESSAGES_DETAILS";
export const CLEAR_MESSAGES_DETAILS = "CLEAR_MESSAGES_DETAILS";

export const GET_DOCUMENT = "GET_DOCUMENT";
export const GET_ACTIVE_CATEGORY = "GET_ACTIVE_CATEGORY";

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_DETAILS = "GET_SUBSCRIPTION_DETAILS";
export const GET_VIEW_SUBSCRIPTION = "GET_VIEW_SUBSCRIPTION";
export const CLEAR_SUBSCRIPTION_DETAILS = "CLEAR_SUBSCRIPTION_DETAILS";

export const CLEAR_USER_QUERY_DETAILS = "CLEAR_USER_QUERY_DETAILS";
export const GET_USER_QUERIES = "GET_USER_QUERIES";

export const CLEAR_BACKGROUND_DETAILS = "CLEAR_BACKGROUND_DETAILS";
export const GET_BACKGROUND = "GET_BACKGROUND";

export const GET_ALL_USERS_SUBSCRPTION ="GET_ALL_USERS_SUBSCRPTION";
export const GET_ALL_USERS_SUBSCRPTION_HISTORY ="GET_ALL_USERS_SUBSCRPTION_HISTORY";
export const GET_ALL_USERS_SUBSCRPTION_HISTORY_DETAILS ="GET_ALL_USERS_SUBSCRPTION_HISTORY_DETAILS";

export const STORE_TOKEN = "STORE_TOKEN";
