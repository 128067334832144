import { combineReducers } from "redux";
import authReducers from "./authReducers.js";
import changeState from "./changeState.js";
import alertReducers from "./alertReducers.js";
import userReducers from "./userReducers.js";
import messagesReducers from "./messagesReducers.js";
import subscriptionReducers from "./subscriptionReducers.js";
import queryReducers from "./queryReducers.js";
import backgroundReducers from "./backgroundReducers.js";
import userSubscriptionReducers from "./userSubscriptionReducers.js";
import userSubcrptionHistoryReducers from "./userSubcrptionHistoryReducers.js";
export default combineReducers({
  auth: authReducers,
  user: userReducers,
  alert: alertReducers,
  subscribe: subscriptionReducers,
  userSubscribe: userSubscriptionReducers,
  userSubcrptionHistory: userSubcrptionHistoryReducers,
  messages: messagesReducers,
  query: queryReducers,
  image: backgroundReducers,
  sidebarBar: changeState, // don't remove this
});
